
import EleChart from 'ele-admin-pro/packages/ele-chart';
import {
    EyeFilled,
    FireFilled,
    FlagFilled,
    ThunderboltFilled
} from '@ant-design/icons-vue';
import usa_map_geo from '@/data/maps/USA.geo.json';
import canada_map_geo from '@/data/maps/Canada.geo.json';

export default {
    name: 'DashboardMonitor',
    components: {
        EleChart,
        EyeFilled,
        FireFilled,
        FlagFilled,
        ThunderboltFilled
    },
    data() {
        return {
            data: {},
            mapData: null,
            currentTime: '20:58:22',
            loadTimer: null
        };
    },
    computed: {
        orderCountData() {
            return this.data.state_statistics || [];
        },
        shipMethodCountData() {
            return this.data.ship_method_statistics || [];
        },
        orderCountMapOption() {
            if (!this.mapData) {
                return null;
            }

            let data = this.orderCountData.map(d => d.value).sort((a, b) => (a - b));

            return {
                tooltip: {
                    trigger: 'item'
                },
                visualMap: {
                    min: 0,
                    max: data[data.length - 1] || 0,
                    text: ['High', 'Low'],
                    calculable: true,
                    color: ['#f63a0f', '#f27011', '#f2b01e', '#f2d31b', '#86e01e']
                },
                series: [{
                    name: '订单数',
                    type: 'map',
                    mapType: 'USA',
                    label: {
                        show: true
                    },
                    data: this.orderCountData
                }]
            };
        },
        orderCountChartOption() {
            let data = this.orderCountData.filter(d => d.value > 0).sort((a, b) => {
                return a.value - b.value;
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    formatter: '<i class="ele-chart-dot" style="background-color:#3aa1ff;"></i>{b0}: {c0}'
                },
                grid: {
                    top: 0,
                    bottom: 0,
                    left: 100,
                    right: 0
                },
                yAxis: [{
                    type: 'category',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    data: data.map(d => d.name)
                }],
                xAxis: [{
                    show: false,
                    type: 'value',
                    splitLine: {
                        show: false
                    }
                }],
                series: [{
                    type: 'bar',
                    data: data.map(d => d.value)
                }]
            };
        },
        shipMethodChartOption() {
            return {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: this.shipMethodCountData.map(d => d.name),
                    bottom: 5,
                    itemWidth: 10,
                    itemHeight: 10,
                    icon: 'circle'
                },
                series: [{
                    type: 'pie',
                    radius: ['45%', '70%'],
                    center: ['50%', '43%'],
                    label: {
                        show: false
                    },
                    data: this.shipMethodCountData
                }]
            };
        }
    },
    mounted() {
        this.getMapData();
        this.doUpdate();
        this.load_data();
    },
    methods: {
        doUpdate() {
            this.currentTime = this.$util.toDateString(new Date(), 'HH:mm:ss');
            this.loadTimer = setInterval(() => {
                this.currentTime = this.$util.toDateString(new Date(), 'HH:mm:ss');

                this.load_data();
            }, 1000 * 60);
        },
        load_data() {
            this.$http.get('/user/dashboard/load_data').then(res => {
                if (res.data.code === 0) {
                    this.data = res.data.data;
                }
            }).catch(() => {});
        },
        getMapData() {
            if(this.$setting.env == 'dh.lbl.morelink56.com') {
                EleChart.registerMap('USA', canada_map_geo, { });
                this.mapData = canada_map_geo;
            } else {
                EleChart.registerMap('USA', usa_map_geo, {
                    Alaska: { // 把阿拉斯加移到美国主大陆左下方
                        left: -131,
                        top: 25,
                        width: 15
                    },
                    Hawaii: {
                        left: -110, // 夏威夷
                        top: 28,
                        width: 5
                    },
                    'Puerto Rico': { // 波多黎各
                        left: -76,
                        top: 26,
                        width: 2
                    }
                });
                this.mapData = usa_map_geo;
            }
        },
        beforeUnmount() {
            // 销毁定时器
            if (this.onlineNumTimer) {
                clearInterval(this.loadTimer);
            }
        }
    }
}
